import styled from 'styled-components'
import { theme } from 'theme'

const VerticalDivider = styled.div`
  background: ${theme.global.colors.border.light};

  width: 100%;
  margin: 12px 0;
  height: 2px;

  @media (min-width: 768px) {
    width: 2px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: ${props => (props.align === 'left' ? 0 : 'auto')};
    margin-right: ${props => (props.align === 'right' ? 0 : 'auto')};
    height: 100%;
  }
`

export default VerticalDivider
