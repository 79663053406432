import React from 'react'
import styled from 'styled-components'

const ResponsiveImg = styled.img`
  display: block;
  object-fit: cover;
  object-position: ${props => props.align} center;
  width: 100%;
  height: auto;
`

const ResponsiveWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(30vw + 100px);
  max-height: 530px;
  picture {
    width: 100%;
    height: 100%;
    display: flex;
  }
`

const Image = ({ imageData, align, alt, className }) => {
  const { file, fluid } = imageData

  return (
    <>
      <ResponsiveWrapper
        ratio={file.details.image.height / file.details.image.width}
        className={className}
      >
        <picture>
          <source srcSet={fluid.srcSetWebp} type="image/webp" />
          <source srcSet={fluid.srcSet} type={file.contentType} />
          <ResponsiveImg src={`${fluid.src}`} alt={alt} align={align} />
        </picture>
      </ResponsiveWrapper>
    </>
  )
}

export default Image
