import React from 'react'
import { Box, Text, Button, Markdown, Paragraph } from 'grommet'
import { Share } from 'grommet-icons'
import styled from 'styled-components'
import { theme } from 'theme'

import Image from 'components/Image'
import { MonoText, responsiveText } from 'components/Type'

const GridBox = styled(Box)`
  margin-bottom: 3%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-clip: content-box;

  @media (max-width: ${theme.global.breakpoints.medium.value}px) {
    flex-basis: 48%;
  }

  @media (max-width: ${theme.global.breakpoints.small.value}px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const InfoBox = styled(Box)`
  width: 100%;
  min-height: 125px;
  position: absolute;
  bottom: 0;
  transition: all 0.3s linear;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  ${GridBox}:hover & {
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.1);
  }
`

const MetaBox = styled(Box)`
  color: ${theme.global.colors.text.light};
`

const DescriptionBox = styled(Box)`
  overflow: hidden;
  transition: all 0.25s linear;
  max-height: 0;
  ${GridBox}:hover & {
    max-height: 300px;
  }
`

const DescriptionMarkdown = styled(Markdown)`
  max-width: 800px;
  margin: 24px 0;
  font-size: 16px;
  line-height: 18px;
`

const ViewButton = styled(Button)`
  padding: 0 10px;
  margin-left: 5px;
`

const ViewButtonText = styled.span`
  color: ${theme.global.colors.brand};
  font-weight: bold;
  font-size: 14px;
  padding-left: 5px;
  opacity: 0;
  display: inline-block;
  transform: scale(0.75);
  transition: all 0.15s linear;
  ${GridBox}:hover &,
  ${ViewButton}:focus & {
    opacity: 1;
    transform: scale(1);
  }
`

const QuoteParagraph = styled(Paragraph)`
  ${responsiveText(16, 22)}
`

const ProjectItem = ({ projectData, ...other }) => {
  const {
    showLarge,
    color,
    alignImage,
    previewImage,
    title,
    madeFor,
    description,
    capabilities,
    tools,
    url,
  } = projectData

  return previewImage && title ? (
    <GridBox
      basis={`calc(${showLarge ? '66.66%' : '33.33%'} - 40px)`}
      pad={{ bottom: '125px' }}
      round="small"
      background={color}
      overflow="hidden"
      border={{ color: 'light-2' }}
      {...other}
    >
      <Box background={color}>
        <Image imageData={previewImage} align={alignImage} alt={`${title} Preview`} />
      </Box>
      <InfoBox pad="medium" background="white">
        <Box direction="row" align="center">
          <Text weight="bold" size="large">
            {title}
          </Text>
          {url && (
            <ViewButton href={url} target="_blank" rel="noreferrer">
              <Share size="small" color="brand" />
              <ViewButtonText>View</ViewButtonText>
            </ViewButton>
          )}
        </Box>
        <MetaBox>
          <MonoText size="small" truncate>
            {madeFor}
          </MonoText>
          <MonoText size="small" truncate>
            {capabilities && capabilities.map(c => ` ${c.name}`).toString()}
          </MonoText>
          <MonoText size="small" truncate>
            {tools && tools.map(t => ` ${t.name}`).toString()}
          </MonoText>
        </MetaBox>
        {description && (
          <DescriptionBox>
            <DescriptionMarkdown>{description.description}</DescriptionMarkdown>
          </DescriptionBox>
        )}
      </InfoBox>
    </GridBox>
  ) : null
}

const TestimonialItem = ({ testimonialData, ...other }) => {
  const { quote, quotee, quoteeTitle, showLarge } = testimonialData

  return quote && quotee ? (
    <GridBox
      basis={`calc(${showLarge ? '66.66%' : '33.33%'} - 40px)`}
      {...other}
      align="center"
      justify="center"
    >
      <Box pad="large">
        <Box direction="row" align="center">
          <QuoteParagraph>&ldquo;{quote.quote}&rdquo;</QuoteParagraph>
        </Box>
        <MetaBox margin={{ top: 'medium' }}>
          <Text weight="bold" size="large">
            {quotee}
          </Text>
          <MonoText size="small">{quoteeTitle}</MonoText>
        </MetaBox>
      </Box>
    </GridBox>
  ) : null
}

export { ProjectItem, TestimonialItem }
