import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

import { Heading, Box, Markdown, Button } from 'grommet'
import styled from 'styled-components'
import { rgba } from 'polished'

import { theme } from 'theme'
import Base from 'layout/Base'
import { ProjectItem, TestimonialItem } from 'components/GridItems'
import Container from 'components/Container'
import VerticalDivider from 'components/VerticalDivider'
import { MonoHeading, responsiveText, fixWidows } from 'components/Type'

const LightHeading = styled(Heading)`
  font-weight: normal;
  max-width: 1200px;
  ${responsiveText(36, 72)}
`

const LightMarkdown = styled(Markdown)`
  h1,
  h2,
  h3 {
    font-weight: normal;
  }
`

const HeroContainer = styled(Container)`
  min-height: 65vh;
  display: flex;
  align-items: center;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  font-size: 1.5rem;
  line-height: 1.5em;
  color: ${theme.global.colors.text.light};
`

const ProjectsBox = styled(Box)`
  @media (min-width: 768px) {
    flex-wrap: wrap;
  }
`

const AboutBox = styled(Box)`
  img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 100%;
  }
`

const IndexPage = ({ data }) => (
  <Base>
    <HeroContainer>
      <Box>
        <Box pad="medium">
          <LightHeading
            level={1}
            size="large"
            margin={{ top: 'xsmall', bottom: 'large' }}
            textAlign="center"
            dangerouslySetInnerHTML={fixWidows(data.index.heading.heading)}
          />
        </Box>

        <Box direction="row-responsive" pad={{ bottom: 'xlarge' }} align="stretch" justify="center">
          <Box basis="2/3" pad="medium">
            <LightMarkdown>{data.index.subheading.subheading}</LightMarkdown>
          </Box>
          <Box pad={{ horizontal: 'large' }}>
            <VerticalDivider />
          </Box>
          <Box basis="1/3" pad="medium">
            <List>
              {data.capabilities.nodes.map((capability, idx) => (
                <ListItem key={idx}>{capability.name}</ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </HeroContainer>
  </Base>
)

export const query = graphql`
  query {
    index: contentfulPage(slug: { eq: "index" }) {
      heading {
        heading
      }
      subheading {
        subheading
      }
    }
    capabilities: allContentfulCapability(sort: { order: ASC, fields: createdAt }) {
      nodes {
        name
      }
    }
  }
`

export default IndexPage
